import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import TrackVisibility from "react-on-screen";

export const History = () => {
  const content = [
    {
      year: "Apr 2022 - Sep 2022",
      description: "Full Stack Developer at MoneyMe",
      comment:
        "Developed APIs in .NET Core with team members to change the process of storing data from Salesforce to in-house developed system",
    },
    {
      year: "Jul 2021 - Apr 2022",
      description: "Graduate Full Stack Engineer at SocietyOne",
      comment:
        "Updated and maintained code for the systems on both frontend (Vue, React) and backend (Golang).",
    },
    {
      year: "Feb 2021 - Jul 2021",
      description: "Diploma of Software Development, TAFE NSW",
      comment:
        "My passion for coding only grew stronger and I wanted to do what I love for a living and become a software developer. As a final project, I’ve built a restaurant management system with 2 other students using .NET and React Native, which we’ve showcased in a presentation event.",
    },
    {
      year: "Jul 2020 – Dec 2020",
      description: "Certificate IV in Programming, TAFE NSW",
      comment:
        "Another thing I was passionate about is putting things together to solve a problem. One of the examples is jigsaw puzzle. When I was studying programming by myself, I’ve found a similarity between jigsaw puzzle and coding. This is why I’ve decided to go to TAFE and learnt by professionals.",
    },
    {
      year: "Apr 2010 – Mar 2015",
      description:
        "BA, English Language and Literature, Prefectural University of Kumamoto, JAPAN",
      comment:
        "I've always been passionate about learning English since I was little. After graduated, I was working as a translator for some time.",
    },
  ];

  return (
    <section className="history" id="history">
      <Container>
        <Row>
          <Col>
            <h2 className="mb-5">History</h2>
            <div className="timeline">
              {content.map((c, i) => {
                return (
                  <TrackVisibility key={i}>
                    {({ isVisible }) => (
                      <div className={isVisible ? "animate__animated animate__pulse" : ""}>
                        <div className="content" key={i}>
                          <div className="year">{c.year}</div>
                          <div className="description">
                            <h4>{c.description}</h4>
                            <div>{c.comment}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </TrackVisibility>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
