import React from "react";
import restaurantSystem from "../assets/img/projects/restaurantmanagementsystem.jpg";
import restaurantSystemMobile from "../assets/img/projects/restaurantmanagementsystem-mobile.jpg";
import inventorySystem from "../assets/img/projects/inventorymanagementsystem.jpg";
import acommodationWeb from "../assets/img/projects/acommodationwebsite.jpg";
import authApp from "../assets/img/projects/authapp.jpg";
import portfolio from "../assets/img/projects/portfolio.jpg";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";

export const Projects = () => {
  const projects = [
    {
      title: "Authentication app",
      description:
        "A simple web app that lets user create their account and log them in using jwt authentication. The backend apis and database are dockerised.",
      imgURL: authApp,
      languages: ["golang", "mysql", "react", "docker"],
    },
    {
      title: "KOTOBUKI",
      description:
        "My portfolio you're looking at right now. It is responsive and can be browsed from mobile device.",
      imgURL: portfolio,
      languages: ["react", "bootstrap"],
    },
    {
      title: "Restaurant Management System",
      description:
        "Highly scalable system for any restaurant to manage their staff and reservations, and for customers to make reservations.",
      imgURL: restaurantSystem,
      languages: ["dotnet", "sqlserver"],
    },
    {
      title: "Restaurant Management System - Mobile app version",
      description:
        "React Native app that interacts with the restaurant management web app. It's for customers to make reservations, browse their reservation history, and request/cancel reservation.",
      imgURL: restaurantSystemMobile,
      languages: ["react"],
    },
    {
      title: "Inventory Management System",
      description:
        "Desktop client app that allows user to manage inventory. A user can check all orders and their statuses. A user can add items that are in stock, and for each order, a user can check all the items details and also change the order's status.",
      imgURL: inventorySystem,
      languages: ["csharp"],
    },
    {
      title: "Acommodation Website",
      description:
        "A website that promotes an area and a place to stay. A user can calculate the cost to stay by clicking the dates from the dropdown, and also send their contact on the contact form.",
      imgURL: acommodationWeb,
      languages: ["html", "css", "js"],
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>Projects</h2>
            <p>
              Hover/Click over an image to see the description. 
              Check the source code{" "}
              <a
                href="https://github.com/suzuka4316"
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav variant="pills" defaultActiveKey="/home">
                <Nav.Item>
                  <Nav.Link eventKey="first">Personal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Work</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.map((p, i) => {
                      return <ProjectCard key={i} {...p} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <p>I've been working in several fintech companies and have maintained systems for customers to apply for loans.</p>
                  <div className="second-tab-achievement-list">
                    <p>Some of my achievements are:</p>
                    <ul>
                      <li>Maintained and added backend apis written in Golang.</li>
                      <li>With team members, built APIs in .NET Core to change the process from saving customers data from current database to in-house database.</li>
                      <li>Maintained the frontend code for web apps and added new features written in Vue.</li>
                      <li>Completely rewrote the marketing website that was previously built using CMS to Headless CMS using Gatsby and Contentful.</li>
                    </ul>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img src={""} className="background-image-right" />
    </section>
  );
};
