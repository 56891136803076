import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/img/logo.svg";
import { TiSocialGithub, TiSocialLinkedin } from "react-icons/ti";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-item-center">
          <Col sm={6} className="align-item-center">
            <img src={Logo} alt="logo" />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://github.com/suzuka4316"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TiSocialGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/suzukafukami"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TiSocialLinkedin />
              </a>
            </div>
            <p>&copy; 2022 Suzuka Fukami</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
