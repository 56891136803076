import { Col } from "react-bootstrap";
import { IconFinder } from "./IconFinder";

export const ProjectCard = ({ title, description, imgURL, languages }) => {
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgURL} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br/>
          {languages.map((l, i) => {
            return (
              <span key={i} className="proj-icon">
                <IconFinder name={l} />
              </span>
            );
          })}
        </div>
      </div>
    </Col>
  );
};
