import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IconFinder } from "./IconFinder";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills</h2>
              {/* <p>add more desc</p> */}
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
              >
                <div className="item">
                  <IconFinder name="golang" />
                  <h5>Golang</h5>
                </div>
                <div className="item">
                  <IconFinder name="dotnet" />
                  <h5>.NET</h5>
                </div>
                <div className="item">
                  <IconFinder name="react" />
                  <h5>React</h5>
                </div>
                <div className="item">
                  <IconFinder name="contentful" />
                  <h5>Headless CMS</h5>
                </div>
                <div className="item">
                  <IconFinder name="vue" />
                  <h5>Vue</h5>
                </div>
                <div className="item">
                  <IconFinder name="aws" />
                  <h5>AWS</h5>
                </div>
                <div className="item">
                  <IconFinder name="buddy" />
                  <h5>Buddy pipeline</h5>
                </div>
                <div className="item">
                  <IconFinder name="jira" />
                  <h5>Jira</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      <img src={""} className="background-image-left" />
    </section>
  );
};
