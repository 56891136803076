import React from "react";
import { GrReactjs } from "react-icons/gr";
import {
  SiContentful,
  SiAmazonaws,
  SiDotnet,
  SiJira,
  SiBuddy,
  SiMysql,
  SiCsharp,
  SiDocker,
  SiBootstrap,
  SiMicrosoftsqlserver,
  SiHtml5,
  SiCss3,
  SiJavascript,
} from "react-icons/si";
import { RiVuejsFill } from "react-icons/ri";

export const IconFinder = ({ name }) => {
  const searchByName = (name) => {
    const lowerCaseName = name.toLowerCase();

    switch (lowerCaseName) {
      case "golang":
        return <i className="devicon-go-original-wordmark"></i>;
      case "react":
        return <GrReactjs />;
      case "contentful":
        return <SiContentful />;
      case "aws":
        return <SiAmazonaws />;
      case "dotnet":
        return <SiDotnet />;
      case "jira":
        return <SiJira />;
      case "buddy":
        return <SiBuddy />;
      case "vue":
        return <RiVuejsFill />;
      case "mysql":
        return <SiMysql />;
      case "csharp":
        return <SiCsharp />;
      case "docker":
        return <SiDocker />;
      case "bootstrap":
        return <SiBootstrap />;
      case "sqlserver":
        return <SiMicrosoftsqlserver />;
      case "html":
        return <SiHtml5 />;
      case "css":
        return <SiCss3 />;
      case "js":
        return <SiJavascript />;
      default:
        return null;
    }
  };

  return <>{searchByName(name)}</>;
};
